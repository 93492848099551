// @flow
import { headed2TokenExchangeServiceUrl, headed2AppUrl } from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import store, { injectAsyncReducers } from 'store';
import jwtDecode from 'jwt-decode';
import session from 'config/session';
import ls from 'utils/localStorage';
import { getFeatureFlags } from 'selectors/featureFlags';

const generateConstants = constantsGenerator('fc/h2Auth');

const [ACCOUNTLINK_STATUS, GET_H2_AUTH_SUCCESS, GET_H2_AUTH_FAIL]: string[] = generateConstants(
  'ACCOUNTLINK_STATUS'
);

const [
  GET_H2_AUTH,
  ACCOUNTLINK_STATUS_SUCCESS,
  ACCOUNTLINK_STATUS_FAIL,
]: string[] = generateConstants('GET_H2_AUTH');

const [LINK_ACCOUNT, LINK_ACCOUNT_SUCCESS, LINK_ACCOUNT_FAIL]: string[] = generateConstants(
  'LINK_ACCOUNT'
);

const [UNLINK_ACCOUNT, UNLINK_ACCOUNT_SUCCESS, UNLINK_ACCOUNT_FAIL]: string[] = generateConstants(
  'UNLINK_ACCOUNT'
);

export type State = {
  loading: boolean,
  errorFlag: boolean,
  authorized: boolean,
  linkStatus: string,
  headed2Endpoint: string,
};

const initialState = {
  loading: false,
  authorized: false,
  linkStatus: 'unlinked',
  errorFlag: false,
  headed2Endpoint: null,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case GET_H2_AUTH:
      return {
        ...state,
        loading: true,
      };
    case GET_H2_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        authorized: action.result,
      };
    }
    case GET_H2_AUTH_FAIL: {
      return {
        ...state,
        loading: false,
        authorized: false,
      };
    }
    case ACCOUNTLINK_STATUS:
      return {
        ...state,
      };
    case ACCOUNTLINK_STATUS_SUCCESS: {
      if (action.result === 'approved') {
        return {
          ...state,
          errorFlag: false,
          linkStatus: 'linked',
        };
      }
      if (action.result === 'awaiting_parent') {
        return {
          ...state,
          errorFlag: false,
          linkStatus: 'pending',
        };
      }
      return {
        ...state,
        errorFlag: false,
        linkStatus: 'unlinked',
      };
    }
    case ACCOUNTLINK_STATUS_FAIL: {
      return {
        ...state,
        linkStatus: 'unlinked',
        errorFlag: true,
      };
    }
    case LINK_ACCOUNT:
      return {
        ...state,
        errorFlag: false,
      };
    case LINK_ACCOUNT_SUCCESS:
      return {
        ...state,
        errorFlag: false,
        headed2Endpoint: action.result,
      };
    case LINK_ACCOUNT_FAIL:
      return {
        ...state,
        errorFlag: true,
      };
    case UNLINK_ACCOUNT:
      return {
        ...state,
        errorFlag: false,
      };
    case UNLINK_ACCOUNT_SUCCESS:
      return {
        ...state,
        linkStatus: 'unlinked',
        errorFlag: false,
      };
    case UNLINK_ACCOUNT_FAIL:
      return {
        ...state,
        errorFlag: true,
      };
    default:
      return state;
  }
}

function fetchH2Auth(url, jwt) {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: jwt,
    },
  })
    .then((data) => data.json())
    .then((body) => !!body.status)
    .catch((err) => {
      console.log('Error: ', err);
    });
}

async function getHeaded2Token() {
  const jwt = session.isValid() ? ls.getItem('deepLinkingAuthorizedToken') : null;
  if (!jwt) {
    return Promise.reject(new Error('Invalid jwt'));
  }
  const state = store.getState();
  const featureFlags = getFeatureFlags(state);
  if (!featureFlags.featureNavianceStudentHeaded2JobSearch) {
    return Promise.reject(new Error('Feature Flag is not turned on for this school'));
  }
  return fetch(`${headed2TokenExchangeServiceUrl}/h2token`, {
    method: 'GET',
    headers: {
      'brapi-token': jwt,
    },
  })
    .then((data) => data.json())
    .then((body) => {
      if (body.h2Token) {
        sessionStorage.setItem('headed2Token', body.h2Token);
        return body.h2Token;
      }
      return Promise.reject(new Error('Invalid headed2 Token'));
    })
    .catch((err) => Promise.reject('Failed to generate headed2 Token', err));
}

async function generateH2Token() {
  let sessionToken = sessionStorage.getItem('headed2Token');
  if (sessionToken) {
    const { exp, externalId } = jwtDecode(sessionToken);
    const { id } = jwtDecode(ls.getItem('deepLinkingAuthorizedToken'));
    // Refresh the token a minute early to avoid latency issues
    const expirationTime = exp * 1000 - 60000;
    if (Date.now() >= expirationTime || externalId !== id) {
      await getHeaded2Token();
      sessionToken = sessionStorage.getItem('headed2Token');
    }
  } else {
    await getHeaded2Token();
    sessionToken = sessionStorage.getItem('headed2Token');
  }
  return sessionToken;
}

function getStateBaseURL(getState) {
  const stateCode = getState().highschool.state;
  sessionStorage.setItem('stateCode', stateCode);
  const stateBaseURL = headed2AppUrl.replace(/app/, stateCode);
  return stateBaseURL;
}

export function getH2Auth() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [GET_H2_AUTH, GET_H2_AUTH_SUCCESS, GET_H2_AUTH_FAIL],
      promise: async () => {
        const h2Token = await generateH2Token();
        const stateBaseURL = getStateBaseURL(getState);
        const isAuthorized = await fetchH2Auth(`${stateBaseURL}/authorization`, h2Token);
        return isAuthorized;
      },
    });
}

async function getOptions(methodType) {
  const h2Token = await generateH2Token();
  if (methodType === 'POST') {
    return {
      method: methodType,
      headers: {
        'Content-Type': 'application/json',
        authorization: h2Token,
      },
      body: JSON.stringify({ backToOriginUrl: window.location.href }),
    };
  }
  return {
    method: methodType,
    headers: {
      'Content-Type': 'application/json',
      authorization: h2Token,
    },
  };
}

async function h2API(retryCount = 0, methodType, getState) {
  let endpoint;
  const stateBaseURL = getStateBaseURL(getState);
  const options = await getOptions(methodType);
  await fetch(`${stateBaseURL}/authorization`, options)
    .then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 422) {
          if (retryCount < 3) {
            retryCount += 1;
            return h2API(retryCount);
          }
          return Promise.reject(new Error('Maximum retries reached'));
        }
        if (response.status === 401 && methodType === 'GET') {
          return response.json();
        }
        return Promise.reject(new Error('Something failed. Try again later'));
      }
      return response.json();
    })
    .then((data) => {
      if (methodType === 'GET') {
        endpoint = data.status || 'unlinked';
      } else if (methodType === 'POST') {
        endpoint = data.headed2LinkPath;
      } else {
        endpoint = true;
      }
      return endpoint;
    })
    .catch((error) => Promise.reject(error));
  return endpoint;
}

export function h2Status() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [ACCOUNTLINK_STATUS, ACCOUNTLINK_STATUS_SUCCESS, ACCOUNTLINK_STATUS_FAIL],
      promise: () => h2API(0, 'GET', getState),
    });
}

export function h2AccountLink() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [LINK_ACCOUNT, LINK_ACCOUNT_SUCCESS, LINK_ACCOUNT_FAIL],
      promise: () => h2API(0, 'POST', getState),
    });
}

export function unlinkH2AccountLink() {
  return (dispatch: Function, getState: Function) =>
    dispatch({
      types: [UNLINK_ACCOUNT, UNLINK_ACCOUNT_SUCCESS, UNLINK_ACCOUNT_FAIL],
      promise: () => h2API(0, 'DELETE', getState),
    });
}

injectAsyncReducers({ h2Auth: reducer });
