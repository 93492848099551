// @flow
import * as React from 'react';
import { connect } from 'utils/connect';
import cx from 'classnames';
import Card from 'components/CardNeon';
import ScrollToTop from 'components/ScrollToTop';
import AccordionMenuNeon, { AccordionMenuSections } from 'components/AccordionMenuNeon';
import { getInitials } from 'selectors/auth';
import {
  NavMenu,
  NavSection,
  getPermittedFavoriteMenu,
  getPermittedProfileMenu,
  getNewMainNavLinks,
  NavLink,
} from 'selectors/careers2NavNeon';
import { Link } from 'components/Link';
import MenuItem from '../Careers2DesktopMenu/MenuItem/index';
import PrimarySection from './Section';
import { NeonButton, NeonIcon } from '@ps-refarch-ux/neon';
import styles from './styles.scss';

interface Props {
  show: boolean;
  user: any;
  sections: NavSection[];
  profileMenus: NavMenu[];
  favoritesMenus: NavMenu[];
  initials: string;
  onClose: () => void;
  onNavigate: () => void;
  onLogout: () => void;
  setModal: (label: string) => void;
}

@connect((state, props) => ({
  initials: getInitials(state, props),
  sections: getNewMainNavLinks(state),
  favoritesMenus: getPermittedFavoriteMenu(state),
  profileMenus: getPermittedProfileMenu(state),
}))
export default class Careers2MobileMenu extends React.PureComponent<Props> {
  componentDidUpdate(oldProps: Props) {
    if (oldProps.show !== this.props.show) {
      // adding this class to <body> prevents overflow issues with position:fixed on iOS
      try {
        document.body.classList[this.props.show ? 'add' : 'remove']('bigmenu-open');
      } catch (e) {
        // nothing to handle here, just a failsafe try/catch block
      }
    }
  }

  componentWillUnmount() {
    // unmount is highly unlikely for hamburger menu but we'll be good and make sure
    // body is cleaned up
    try {
      document.body.classList.remove('bigmenu-open');
    } catch (e) {
      // nothing to handle here, just a failsafe try/catch block
    }
  }

  getTitle = () => (
    <section className={styles.cardTitle}>
      <h1 className={cx(styles.menuHeader, 'h2')}>Menu</h1>
      <NeonButton
        id="close-button"
        dataType="utility"
        className={styles.hamburgerBtn}
        dataText=""
        dataIcon={'close'}
        onClick={this.props.onClose}
      />
    </section>
  );

  logout = (e: MouseEvent) => {
    e.preventDefault();
    this.props.onLogout();
  };

  renderLinks = (links: NavLink[]) =>
    links.map((link) => (
      <MenuItem
        {...link}
        onNavigate={this.props.onNavigate}
        setModal={this.props.setModal}
        key={link.label}
      />
    ));

  renderMenus = (menus: NavMenu[]) =>
    menus.map((menu) => (
      <div key={menu.label}>
        {menu.topLinks && this.renderLinks(menu.topLinks)}
        {menu.links.length && menu.label && (
          <span className={styles.menuTitle}>{menu.label}</span>
        )}
        {menu.links && this.renderLinks(menu.links)}
        {menu.bottomMenu && (
          <div className={styles.bottomMenu}>{this.renderMenus(menu.bottomMenu)}</div>
        )}
        {menu.menuFooter && <div className={styles.menuFooter}>{menu.menuFooter}</div>}
      </div>
    ));

  render() {
    const { show, user, sections, favoritesMenus, profileMenus, initials } = this.props;

    const home = sections.find((s) => s.label === 'Home');

    const newLinks: AccordionMenuSections = sections
      .filter((s) => s.label !== 'Home')
      .map((section) => ({
        expandedCss: styles.expanded,
        sectionCss: styles.accordionSection,
        header: (
          <PrimarySection
            title={section.label}
            noBorder
            key={`header-${section.to}`}
            linkCss={styles.accordionHeader}
          />
        ),
        childItems: this.renderMenus(section.menus),
      }));

    const favorites: AccordionMenuSections = [
      {
        expandedCss: styles.expanded,
        sectionCss: styles.accordionSection,
        header: (
          <PrimarySection
            title="Favorites"
            noBorder
            icon="heart-solid"
            key={`header-favorites`}
            linkCss={styles.accordionHeader}
          />
        ),
        childItems: this.renderMenus(favoritesMenus),
      },
    ];

    const profile: AccordionMenuSections = [
      {
        expandedCss: styles.expanded,
        sectionCss: styles.accordionSection,
        header: (
          <PrimarySection
            title="About Me"
            noBorder
            avatar={<div className={styles.avatar}>{initials}</div>}
            key={`header-favorites`}
            linkCss={styles.accordionHeader}
            caption={`Logged in as ${user.fullName}`}
          />
        ),
        childItems: this.renderMenus(profileMenus),
      },
    ];

    return (
      <div className={cx(styles.mobileNav, { [styles.mobileNavActive]: show })}>
        <Card
          title={this.getTitle()}
          data-test-id="mobile_nav"
          data-user={user && `${user.firstName} ${user.lastName}`}
          type="lightpurple"
          noPadding
        >
          {show ? <ScrollToTop /> : null}
          {show && (
            <div className={cx(styles['mobileNav-home'])}>
              <NeonIcon dataIcon={home.icon} />
              <Link to={home.to} tabIndex={0} onClick={this.props.onClose}>
                Naviance Home
              </Link>
            </div>
          )}
          {show ? (
            <AccordionMenuNeon sections={[...newLinks, ...favorites, ...profile]} />
          ) : null}
        </Card>

        <NeonButton
          id="logout-button"
          dataType="primary"
          dataText={'Log Out'}
          className={styles.logoutBtn}
          onClick={this.logout}
        />
      </div>
    );
  }
}
