import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { batch, DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getSessionExpirationDate } from 'selectors/auth';
import { logout, extendSession } from 'modules/auth';
import { getHsid } from 'selectors/highschool';
import { setHsid } from 'modules/highschool';
import ExtendSessionModal from './ExtendSessionModal';

export const getSecondsTillExpiration = (sessionExpiration: Date) => {
  if (!moment(sessionExpiration).isValid()) {
    return 0;
  }

  return Math.floor((sessionExpiration.valueOf() - Date.now()) / 1000);
};

export const FIVE_MINUTES = 5 * 60;
export const SIX_MINUTES = 6 * 60;

export const ACTIVITY_EVENTS = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];
const events = ACTIVITY_EVENTS;
const COUNTDOWN_INTERVAL = 1000;
const IDLE_TIMEOUT = 10000;

let countDownIntervalId: NodeJS.Timeout;
let idleTimeoutId: NodeJS.Timeout;
let eventsBound;

function ActivityWatcher() {
  const history = useHistory();
  const dispatch = useDispatch();
  const sessionExpiration = useSelector<DefaultRootState, Date>(getSessionExpirationDate);
  const hsId = useSelector(getHsid);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [secondsRemaining, setSecondsRemaining] = useState<number>(10000000);
  const [isIdle, setIsIdle] = useState<boolean>(false);

  function startIdleTimer() {
    const shouldNotStartTimeout = showModal || idleTimeoutId;
    if (shouldNotStartTimeout) {
      return;
    }

    idleTimeoutId = setTimeout(() => {
      setIsIdle(true);
    }, IDLE_TIMEOUT);
  }

  function clearIdleTimeout() {
    if (idleTimeoutId) {
      clearTimeout(idleTimeoutId);
      idleTimeoutId = undefined;
    }
  }

  function clearCountDownInterval() {
    if (countDownIntervalId) {
      clearInterval(countDownIntervalId);
      countDownIntervalId = undefined;
    }
  }

  async function handleActivity() {
    clearIdleTimeout();
    setIsIdle(false);
    startIdleTimer();
  }

  function bindEvents() {
    if (!eventsBound) {
      events.forEach((e: string) => {
        window.addEventListener(e, handleActivity);
      });
      eventsBound = true;
    }
  }

  function unbindEvents() {
    if (eventsBound) {
      events.forEach((e: string) => {
        window.removeEventListener(e, handleActivity);
      });
      eventsBound = false;
    }
  }

  function sessionExpired(secondsToExpiration: number): boolean {
    return secondsToExpiration <= 0;
  }

  function sessionAboutToExpired(secondsToExpiration: number): boolean {
    return secondsToExpiration <= SIX_MINUTES;
  }

  function startCountDown() {
    countDownIntervalId = setInterval(async () => {
      const secondsToExpiration = getSecondsTillExpiration(sessionExpiration);

      if (sessionExpired(secondsToExpiration)) {
        await logOut();
        return;
      }
      const shouldShowModal = secondsToExpiration <= FIVE_MINUTES && !showModal;

      ReactDOM.unstable_batchedUpdates(() => {
        if (shouldShowModal) {
          setShowModal(true);
        }

        setSecondsRemaining(secondsToExpiration);
      });
    }, COUNTDOWN_INTERVAL);
  }

  function stopCountDown() {
    clearCountDownInterval();
  }

  function shouldStartCountdown() {
    return isIdle && !countDownIntervalId;
  }

  function shouldStopCountdown() {
    return !showModal && countDownIntervalId;
  }

  async function manageIdleState() {
    if (shouldStartCountdown()) {
      startCountDown();
      return;
    }

    if (shouldStopCountdown()) {
      stopCountDown();
    }

    const secondsToExpiration = getSecondsTillExpiration(sessionExpiration);
    const shouldExtendSession =
      sessionAboutToExpired(secondsToExpiration) && !showModal && !isIdle;
    if (shouldExtendSession) {
      await handleExtendSession();
    }
  }

  async function watchForUserActivity() {
    // if session has expired, there is no need to watch, let's log the user out
    const secondsToExpiration = getSecondsTillExpiration(sessionExpiration);
    const sessionExpiredOrIncorrect = sessionExpired(secondsToExpiration);
    if (sessionExpiredOrIncorrect) {
      await logOut();
      return;
    }

    startIdleTimer();
  }

  // When IDLE state changes, we need to figure out if we must show the modal or extend the session
  useEffect(() => {
    // tslint:disable-next-line:no-console
    manageIdleState().catch(console.error);
  }, [isIdle]);

  // Start watching for user activity:
  // If user doesn't interact with application after IDLE_TIMEOUT, we set IDLE state to true.
  useEffect(() => {
    bindEvents();
    // tslint:disable-next-line:no-console
    watchForUserActivity().catch(console.error);

    return () => {
      unbindEvents();
    };
  }, [sessionExpiration, showModal]);

  // Make sure we are cleaning timeouts,intervals and bound events; typically when user logs out.
  useEffect(() => {
    return () => {
      clearCountDownInterval();
      clearIdleTimeout();
      unbindEvents();
    };
  }, []);

  const logOut = useCallback(async () => {
    clearCountDownInterval();
    await dispatch(logout());
    dispatch(setHsid(hsId));
    history.push(`/${hsId}`);
  }, [hsId, countDownIntervalId]);

  const handleExtendSession = useCallback(async () => {
    batch(async () => {
      await dispatch(extendSession());
      setShowModal(false);
      clearCountDownInterval();
    });
  }, []);

  if (!hsId) {
    return null;
  }

  return (
    <ExtendSessionModal
      onLogOut={logOut}
      onExtendSession={handleExtendSession}
      secondsRemaining={secondsRemaining}
      showModal={showModal}
    />
  );
}

export default ActivityWatcher;
