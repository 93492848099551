export const parentView = {
  largeText:
    "Use Headed2 in Naviance to explore \n options for your student's future and put \n career plans into action.",
  boldText:
    'Students must create a Headed2 \n account and link it with their Naviance \n account to get started.',
  firstParagraph: '',
  secondParagraph:
    'Students can unlink Naviance and Headed2 anytime from the \n My Account section of About Me.',
  showFooterButton: false,
};

export const studentPendingView = {
  largeText: '',
  boldText: 'Awaiting parent approval \n to link your accounts?',
  firstParagraph: 'Go to Headed2 and log in \n to check the approval status.',
  secondParagraph: '',
  showFooterButton: true,
};

export const studentUnlinkView = {
  largeText:
    'Use Headed2 in Naviance to explore options for \n your future and put your career plans into action.',
  boldText: 'Create a Headed2 account to get started.',
  firstParagraph:
    'Already have a Headed2 account? \n Link your Headed2 and Naviance accounts to get started.',
  secondParagraph:
    'You can unlink Naviance and Headed2 anytime from \n the My Account section of About Me.',
  showFooterButton: true,
};
