// @flow
import api from 'config/api';
import { injectAsyncReducers } from 'store';
import session from 'config/session';
import ss from 'utils/sessionStorage';
import constantsGenerator from 'utils/constantsGenerator';
import { IMPERSONATE_SUCCESS } from 'modules/parent';
import { notify } from './notification';
import getUrlParams from '../utils/getUrlParams';

const generateConstants = constantsGenerator('fc/auth');
const CHANGE_USERNAME = 'fc/auth/CHANGE_USERNAME';
const CHANGE_LOGIN_USERTYPE = 'fc/auth/CHANGE_LOGIN_USERTYPE';

const [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL]: string[] = generateConstants('login');
const [EXTEND_SESSION, EXTEND_SESSION_SUCCESS, EXTEND_SESSION_FAIL]: string[] = generateConstants(
  'extend_session'
);
const [COOKIE_LOGIN, COOKIE_LOGIN_SUCCESS, COOKIE_LOGIN_FAIL]: string[] = generateConstants(
  'cookie_login'
);
const [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL]: string[] = generateConstants('logout');
const [STOP_GHOSTING, STOP_GHOSTING_SUCCESS, STOP_GHOSTING_FAIL]: string[] = generateConstants(
  'ghosting'
);

const [CURRENT_USER, CURRENT_USER_SUCCESS, CURRENT_USER_FAIL]: string[] = generateConstants('user');

export type State = {
  token?: string,
  exp?: number,
  highSchoolAlias?: string,
  iat?: number,
  permissions?: Object,
  role?: string,
  username?: string,
  loggingIn: boolean,
  afterLogin: boolean,
  loading: boolean,
  userLoaded: boolean,
  userType: string,
};

const oldSessionData = session.data || {};

const initialState = {
  token: undefined,
  exp: undefined,
  highSchoolAlias: undefined,
  iat: undefined,
  permissions: undefined,
  role: undefined,
  username: undefined,
  loggingIn: false,
  afterLogin: false,
  loading: false,
  userLoaded: false,
  userType: undefined,
  ...oldSessionData,
};

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case LOGIN:
    case COOKIE_LOGIN:
      return {
        token: '',
        loggingIn: true,
      };
    case EXTEND_SESSION:
      return { ...state, token: '' };
    case LOGIN_SUCCESS:
    case COOKIE_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.result,
        ...session.register(action.result),
        loggingIn: false,
        afterLogin: true,
        loading: false,
      };
    case EXTEND_SESSION_SUCCESS:
      return {
        ...state,
        token: action.result,
        ...session.register(action.result),
      };
    case IMPERSONATE_SUCCESS:
      return {
        ...state,
        token: action.result,
        ...session.register(action.result),
        loggingIn: false,
        afterLogin: false,
      };
    case LOGIN_FAIL:
    case COOKIE_LOGIN_FAIL:
      return {
        ...state,
        currentUser: null,
        loggingIn: false,
        loading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        token: '',
        loggingIn: false,
        afterLogin: false,
      };
    case LOGOUT_FAIL:
      return {
        token: '',
        loggingIn: false,
        afterLogin: false,
      };
    case CURRENT_USER:
      return {
        ...state,
        loading: true,
      };
    case CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userLoaded: true,
        currentUser: action.result,
      };
    case CURRENT_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_USERNAME:
      return {
        ...state,
        username: action.username,
      };
    case CHANGE_LOGIN_USERTYPE:
      return {
        ...state,
        userType: action.userType,
      };
    case EXTEND_SESSION_FAIL:
    default:
      return state;
  }
}

export function setLoginUserType(userType: string | null) {
  if (!userType) {
    return null;
  }
  return (dispatch: Function) => dispatch({ type: CHANGE_LOGIN_USERTYPE, userType });
}

export function login(
  username: string | null,
  password: string | null,
  highSchoolAlias: string,
  highSchoolId: string | null,
  captchaToken: string | null,
  asGuest: boolean | null,
  userType: string | null
): Function {
  const payload = {
    username,
    password,
    highSchoolAlias,
    highSchoolId,
    asGuest,
    captchaToken,
  };

  return (dispatch: Function) =>
    dispatch({
      types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
      promise: (client: Object) =>
        client
          .post(`${api.host}/security/login`, {
            data: payload,
            params: {
              credentials: 'include',
            },
          })
          .then((token) => {
            session.register(token);
            // save the cookie generated after login for FC
            // used for view as student or demo user, to force refresh of session data
            session.registerCookie();
            dispatch(notify());
            return token;
          })
          .catch((response) => {
            const {
              error: { error, id },
            } = response;

            if (userType) {
              dispatch(setLoginUserType(userType));
            }

            return dispatch(
              notify({
                type: 'danger',
                content: error,
                id,
              })
            );
          }),
    });
}

export function extendSession(): Function {
  return (dispatch: Function) =>
    dispatch({
      types: [EXTEND_SESSION, EXTEND_SESSION_SUCCESS, EXTEND_SESSION_FAIL],
      promise: (client: Object) =>
        client
          .post(`${api.host}/security/extend-session`, {
            params: {
              credentials: 'include',
            },
          })
          .then((token) => {
            session.register(token);
            // save the cookie generated after login for FC
            // used for view as student or demo user, to force refresh of session data
            session.registerCookie();
            dispatch(notify());
            return token;
          })
          .catch((response) => {
            const {
              error: { error, id },
            } = response;
            return dispatch(
              notify({
                type: 'danger',
                content: error,
                id,
              })
            );
          }),
    });
}

export function checkLogin() {
  return (dispatch: Function) => {
    const token = ss.getItem('token');

    if (session.isValid()) {
      return dispatch({ type: LOGIN_SUCCESS, result: token });
    }

    // if there is no token, try to get one via session cookie
    const apiUrl = `${api.host}/security/session`;
    const urlParams = getUrlParams(location.search);
    if (urlParams.userId && urlParams.parentUserId) {
      throw new Error(
        `Both student id ${urlParams.userId} and parent user id ${urlParams.parentUserId} exist`
      );
    }

    let userIdParam;
    if (urlParams.userId) {
      userIdParam = `userId=${urlParams.userId}`;
    }
    if (urlParams.parentUserId) {
      userIdParam = `parentUserId=${urlParams.parentUserId}`;
    }
    const sessionUrl = userIdParam ? `${apiUrl}?${userIdParam}` : apiUrl;
    return dispatch({
      types: [COOKIE_LOGIN, COOKIE_LOGIN_SUCCESS, COOKIE_LOGIN_FAIL],
      promise: (client: Object) =>
        client
          .post(sessionUrl, {
            params: {
              credentials: 'include',
            },
          })
          .then((jwt) => {
            // recover cookie value for session storage
            session.registerCookie();
            return jwt;
          }),
    });
  };
}

export function logout() {
  return (dispatch: Function, getState: Function) => {
    const { auth } = getState();
    const counselor = auth.counselor || auth.staffUser || {};
    // if we are a counselor we don't logout we only close browser window
    if (counselor.id) {
      const closeWindow = function () {
        session.clear(true);
        window.close();
      };

      return dispatch({
        types: [STOP_GHOSTING, STOP_GHOSTING_SUCCESS, STOP_GHOSTING_FAIL],
        promise: (client: Object) =>
          client
            .post(`${api.security}/stop-ghosting`, {
              params: {
                credentials: 'include',
              },
            })
            .then(closeWindow)
            .catch(closeWindow),
      });
    }

    return dispatch({
      types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
      promise: (client: Object) =>
        client
          .post(`${api.security}/logout`, {
            params: {
              credentials: 'include',
            },
          })
          .then((response) => {
            session.clear();
            // If the user logs in through Auth0, there will be a `auth0LogoutUrl` added to the user session.
            // The backend will send the `auth0LogoutUrl` thru response if it's present,
            // and the UI should follow the Url to clear Auth0 cookie.
            if (typeof response === 'string') {
              window.location.replace(response);
            }
          })
          .catch(() => {
            session.clear();
          }),
    });
  };
}

export function getCurrentUserData(force: boolean): Function {
  return (dispatch: Function, getState: Function) => {
    const state: State = getState().auth;

    if (!force && (state.userLoaded || state.loading)) return Promise.resolve(state);

    return dispatch({
      types: [CURRENT_USER, CURRENT_USER_SUCCESS, CURRENT_USER_FAIL],
      promise: (client: Object) => client.get(`${api.users}/me`),
    });
  };
}

export function changeUsername(username: string) {
  return {
    type: CHANGE_USERNAME,
    username,
  };
}

injectAsyncReducers({ auth: reducer });
