// @flow
import api from 'config/api';
import { injectAsyncReducers } from 'store';
import collectIds from 'utils/collectIds';
import constantsGenerator from 'utils/constantsGenerator';
import normalizeItems from 'utils/normalizeItems';
import surveyStatuses from 'constants/surveyStatuses';
import { notify } from './notification';

const generateConstants = constantsGenerator('fc/surveys');
const [GET, GET_SUCCESS, GET_FAIL]: Array<string> = generateConstants('get');
export const [
  SAVE_RESPONSE,
  SAVE_RESPONSE_SUCCESS,
  SAVE_RESPONSE_FAIL,
]: Array<string> = generateConstants('save-response');
const [GET_QUESTIONS, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAIL]: Array<string> = generateConstants(
  'get-questions'
);
const [
  GET_OUTCOMES_CODE,
  GET_OUTCOMES_CODE_SUCCESS,
  GET_OUTCOMES_CODE_FAIL,
]: Array<string> = generateConstants('get-outcomes-code');

export const [
  GET_RESPONSES,
  GET_RESPONSES_SUCCESS,
  GET_RESPONSES_FAIL,
]: Array<string> = generateConstants('get-responses');

const [GET_RESPONSE, GET_RESPONSE_SUCCESS, GET_RESPONSE_FAIL]: Array<string> = generateConstants(
  'get-response'
);

const [
  SAVE_GRAD_SURVEY_RESPONSE,
  SAVE_GRAD_SURVEY_RESPONSE_SUCCESS,
  SAVE_GRAD_SURVEY_RESPONSE_FAIL,
]: Array<string> = generateConstants('SAVE_GRAD_SURVEY_RESPONSE');

const ALTER_RESPONSE_DATA = 'fc/surveys/ALTER_RESPONSE_DATA';
const CACHE_ENTRY = 'fc/surveys/cacheEntry';
const CLEAR_CACHE_ENTRY = 'fc/surveys/clearCacheEntry';
const SET_SURVEY_CONTINUE_FROM = 'fc/surveys/SET_SURVEY_CONTINUE_FROM';
const CREATE_EMPTY_GRADUATION_SURVEY_RESPONSE =
  'fc/surveys/CREATE_EMPTY_GRADUATION_SURVEY_RESPONSE';
const REQUEST_VIEW_SURVEY = 'fc/surveys/REQUEST_VIEW_SURVEY';

export type State = {
  surveys: {
    entities: Object,
    results: Array<Object>,
    loaded: boolean,
    loading: boolean,
  },
  questions: {
    entities: Object,
    results: Array<Object>,
    loaded: boolean,
    loading: boolean,
    surveyId: string,
  },
  outcomeCodes: {
    results: Array<Object>,
    loaded: boolean,
    loading: boolean,
  },
  responses: {
    entities: Object,
    results: Array<Object>,
    loaded: boolean,
    loading: boolean,
    invalidated: boolean,
  },
  response: {
    entity: ?Object,
    loaded: boolean,
    loading: boolean,
    cacheModel: ?Object,
  },
  continueFrom: number,
  viewStudentResponses: boolean,
};

const initialState = {
  surveys: {
    entities: {},
    results: [],
    entitiesStudent: {},
    resultsStudent: [],
    loaded: false,
    loading: false,
  },
  questions: {
    entities: {},
    results: [],
    loaded: false,
    loading: false,
    surveyId: '',
  },
  outcomeCodes: {
    loaded: false,
    loading: false,
    results: [],
  },
  responses: {
    entities: {},
    results: [],
    entitiesStudent: {},
    resultsStudent: [],
    loaded: false,
    loading: false,
    invalidated: false,
  },
  response: {
    entity: null,
    loaded: false,
    loading: false,
    cacheModel: null,
  },
  continueFrom: 0,
  viewStudentResponses: false,
};

const normalizeStatus = (responses: Object[]) =>
  responses.map((r) => ({
    ...r,
    status: r.status === surveyStatuses.DONE && r.survey.isEditable === 2 ? 1 : r.status,
  }));

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object): Object {
  switch (action.type) {
    case CACHE_ENTRY:
      return {
        ...state,
        response: {
          ...state.response,
          cacheModel: {
            [action.payload.surveyId]: action.payload.values,
          },
        },
      };
    case CLEAR_CACHE_ENTRY:
      return {
        ...state,
        response: {
          ...state.response,
          cacheModel: () => {
            const { cacheModel } = state.response;

            if (!cacheModel) return null;

            const { [action.payload.surveyId]: omit, ...rest } = cacheModel;

            return rest;
          },
        },
      };
    case GET:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          loading: true,
        },
      };
    case GET_SUCCESS: {
      let surveys = {};
      // we save student surveys
      if (action.forStudent) {
        surveys = {
          entitiesStudent: normalizeItems(action.result, 'id'),
          resultsStudent: collectIds(action.result, 'id'),
        };
      } else {
        surveys = {
          entities: normalizeItems(action.result, 'id'),
          results: collectIds(action.result, 'id'),
        };
      }

      return {
        ...state,
        surveys: {
          ...state.surveys,
          ...surveys,
          loaded: true,
          loading: false,
        },
      };
    }

    case GET_FAIL:
      return {
        ...state,
        surveys: {
          ...state.surveys,
          loaded: false,
          loading: false,
        },
      };
    case GET_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true,
        },
      };
    case GET_QUESTIONS_SUCCESS: {
      const questionsResult = action.result
        .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        .map((question, i) => ({
          ...question,
          sequenceNumber: i + 1,
        }));

      return {
        ...state,
        questions: {
          ...state.questions,
          entities: normalizeItems(questionsResult, 'id'),
          results: collectIds(questionsResult, 'id'),
          loaded: true,
          loading: false,
          surveyId:
            Array.isArray(questionsResult) && questionsResult.length
              ? questionsResult[0].surveyId
              : '',
        },
      };
    }
    case GET_QUESTIONS_FAIL:
      return {
        ...state,
        questions: {
          ...state.questions,
          loaded: false,
          loading: false,
        },
      };
    case GET_OUTCOMES_CODE:
      return {
        ...state,
        outcomeCodes: {
          ...state.outcomeCodes,
          loading: true,
        },
      };
    case GET_OUTCOMES_CODE_SUCCESS: {
      const results = action.result.sort((a, b) => a.name - b.name);
      return {
        ...state,
        outcomeCodes: {
          results,
          loading: true,
        },
      };
    }
    case GET_OUTCOMES_CODE_FAIL:
      return {
        ...state,
        questions: {
          ...state.outcomeCodes,
          loaded: false,
          loading: false,
        },
      };
    case GET_RESPONSES:
      return {
        ...state,
        responses: {
          ...state.responses,
          loading: true,
        },
      };
    case GET_RESPONSES_SUCCESS: {
      let responses = {};
      // we save student responses
      if (action.forStudent) {
        responses = {
          entitiesStudent: normalizeItems(normalizeStatus(action.result || []), 'id'),
          resultsStudent: collectIds(action.result, 'id'),
        };
      } else {
        responses = {
          entities: normalizeItems(normalizeStatus(action.result.data || []), 'id'),
          results: collectIds(action.result.data, 'id'),
        };
      }
      return {
        ...state,
        responses: {
          ...state.responses,
          ...responses,
          loaded: true,
          loading: false,
          invalidated: false,
        },
      };
    }
    case CREATE_EMPTY_GRADUATION_SURVEY_RESPONSE:
      return {
        ...state,
        response: {
          ...state.response,
          entity: {
            ...state.response.entity,
            outcome: 0,
          },
        },
      };
    case GET_RESPONSES_FAIL:
      return {
        ...state,
        responses: {
          ...state.responses,
          loaded: false,
          loading: false,
        },
      };
    case GET_RESPONSE:
      return {
        ...state,
        response: {
          ...state.response,
          loading: true,
        },
      };
    case GET_RESPONSE_SUCCESS:
      return {
        ...state,
        response: {
          entity: action.result,
          loaded: true,
          loading: false,
        },
      };
    case ALTER_RESPONSE_DATA:
      return {
        ...state,
        response: {
          entity: {
            ...state.response.entity,
            [action.name]: action.value,
          },
        },
      };
    case GET_RESPONSE_FAIL:
      return {
        ...state,
        response: {
          ...state.response,
          loaded: false,
          loading: false,
        },
      };
    case SET_SURVEY_CONTINUE_FROM:
      return {
        ...state,
        continueFrom: action.from,
      };
    case REQUEST_VIEW_SURVEY:
      return {
        ...state,
        viewStudentResponses: action.viewStudent,
      };
    case SAVE_GRAD_SURVEY_RESPONSE_SUCCESS:
      return {
        ...state,
        responses: {
          ...state.responses,
          invalidated: true,
        },
      };
    default:
      return state;
  }
}

export function cacheEntry(surveyId: string, values: { [string]: Object }) {
  return {
    type: CACHE_ENTRY,
    payload: {
      surveyId,
      values,
    },
  };
}

export function clearCacheEntry(surveyId: string) {
  return {
    type: CLEAR_CACHE_ENTRY,
    payload: {
      surveyId,
    },
  };
}

export function fetchOutcomeCodes(): Function {
  return (dispatch: Function): Promise<Action | void> =>
    dispatch({
      types: [GET_OUTCOMES_CODE, GET_OUTCOMES_CODE_SUCCESS, GET_OUTCOMES_CODE_FAIL],
      promise: (client: Object) => client.get(`${api.host}/surveys/outcome-codes`),
    });
}

export function fetchSurveys(forStudent: boolean = false): Function {
  return (dispatch: Function): Promise<Action | void> =>
    dispatch({
      types: [GET, GET_SUCCESS, GET_FAIL],
      promise: (client: Object) =>
        client.get(`${api.host}/surveys/my-surveys?forStudent=${String(forStudent)}`),
      forStudent,
    });
}

export function searchSurveysResponses(force: boolean, forStudent: boolean = false): Function {
  return (dispatch: Function): Promise<Action | void> => {
    const url = `${api.host}/surveys/response/${forStudent ? `student` : `search`}`;

    return dispatch({
      types: [GET_RESPONSES, GET_RESPONSES_SUCCESS, GET_RESPONSES_FAIL],
      promise: (client: Object) => client.get(url),
      forStudent,
    });
  };
}

export function saveSurveyResponse(
  surveyId: string,
  responseSet: number | undefined,
  data: Object[],
  status: number
): Function {
  return (dispatch: Function): Promise<Action | void> =>
    dispatch({
      types: [SAVE_RESPONSE, SAVE_RESPONSE_SUCCESS, SAVE_RESPONSE_FAIL],
      promise: (client: Object) =>
        client.post(`${api.host}/surveys/${surveyId}/response/${responseSet || ''}`, {
          data: {
            responses: data,
            status,
          },
        }),
    }).catch(() => {
      dispatch(
        notify({
          type: 'danger',
          content: 'Something went wrong. Please try again later!',
        })
      );
      return Promise.reject();
    });
}

export function saveGraduationSurveyResponses(data: Object, step: number) {
  return {
    types: [
      SAVE_GRAD_SURVEY_RESPONSE,
      SAVE_GRAD_SURVEY_RESPONSE_SUCCESS,
      SAVE_GRAD_SURVEY_RESPONSE_FAIL,
    ],
    promise: (client: Object) =>
      client.post(`${api.surveys}/graduation/response/${step + 1}`, {
        data: {
          ...data,
          outcome: parseInt(data.outcome, 10),
          militaryBranch: parseInt(data.militaryBranch, 10),
        },
      }),
  };
}

export function fetchSurveyQuestions(id: string): Function {
  return (dispatch: Function): Promise<Action | void> =>
    dispatch({
      types: [GET_QUESTIONS, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAIL],
      promise: (client: Object) => client.get(`${api.host}/surveys/${id}/questions`),
    });
}

export function createEmptyGraduationSurveyResponse() {
  return {
    type: CREATE_EMPTY_GRADUATION_SURVEY_RESPONSE,
  };
}

export function fetchSurveyResponse(
  surveyId: string,
  responseSet: number,
  forStudent: boolean = false
): Function {
  return (dispatch: Function): Promise<Action | void> =>
    dispatch({
      types: [GET_RESPONSE, GET_RESPONSE_SUCCESS, GET_RESPONSE_FAIL],
      promise: (client: Object) =>
        client.get(
          `${api.host}/surveys/${surveyId}/response/${responseSet || ''}?forStudent=${String(
            forStudent
          )}`
        ),
    }).catch(() => {
      if (surveyId === 'graduation') {
        dispatch(createEmptyGraduationSurveyResponse());
      }

      dispatch({
        type: GET_RESPONSE_FAIL,
      });
    });
}

export function setSurveyContinueFrom(from: number) {
  return {
    type: SET_SURVEY_CONTINUE_FROM,
    from,
  };
}

export function alterResponseData(name: string, value: any) {
  return {
    type: ALTER_RESPONSE_DATA,
    name,
    value,
  };
}

export function requestToView(viewStudent: boolean) {
  return {
    type: REQUEST_VIEW_SURVEY,
    viewStudent,
  };
}

injectAsyncReducers({ surveys: reducer });
