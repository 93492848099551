import withGlobals from 'components/Globals';
import PageTemplate from 'components/PageTemplate';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ScriptLoader from 'react-script-loader-hoc';
import { connect } from 'react-redux';
import { getFeatureFlags } from 'selectors/featureFlags';
import { getPermissions } from 'selectors/permissions';
import api, { careerPathwayUiUrl } from 'config/api';
import session from '../../config/session';
import { FeatureFlags } from 'types/featureFlags';
import { HSPermissions } from 'types/permissions';
import Icon from 'components/Icon';

interface CareersPathwayProps extends RouteComponentProps {
  scriptsLoaded: boolean;
  currentUser: {};
  featureFlags: FeatureFlags;
  permissions: HSPermissions;
}

const CareersUIWrapper: React.FunctionComponent<CareersPathwayProps> = ({
  history,
  currentUser,
  featureFlags,
  permissions,
}) => {
  const divId = 'career-pathway';
  const bootstrapName = 'CareersPathway';
  const inputRef = React.useRef();
  const [isAppBootstrapped, setAppBootstrapped] = useState<boolean>(false);
  useEffect(() => {
    const interval = setInterval(() => {
      const bootstrapFunction = (window as any)[bootstrapName];
      if (typeof bootstrapFunction === 'function' && !!document.getElementById(divId)) {
        // only call bootstrap once
        if (!isAppBootstrapped) {
          bootstrapFunction(divId);
          setAppBootstrapped(true);
        }
        clearInterval(interval);
      }
    }, 100);
    // just fail after 30 seconds, and stop trying.
    setTimeout(() => {
      if (!isAppBootstrapped) {
        // tslint:disable-next-line:no-console
        console.error(`FAILED TO Bootstrap ${bootstrapName}`);
      }
      if (interval) {
        clearInterval(interval);
      }
    }, 30000);
    return () => clearInterval(interval); // clear interval on unmount
  }, []);
  const { data, isValid } = session;
  const userSession = { userSession: data, isValid, currentUser };

  return (
    <PageTemplate title="Careers UI">
      <div
        id={divId}
        ref={inputRef}
        x-career-api-url={api.career}
        x-api-host={api.host}
        x-ns-jwt={session.jwt}
        x-userSession={JSON.stringify(userSession)}
        x-fcuiRouterHistory={JSON.stringify(history)}
        x-icon={Icon}
        x-featureFlags={JSON.stringify(featureFlags)}
        x-permissions={JSON.stringify(permissions)}
      />
    </PageTemplate>
  );
};

export default withGlobals(
  connect((state) => ({
    auth: (state as any).auth,
    featureFlags: getFeatureFlags(state),
    permissions: getPermissions(state),
  }))(ScriptLoader(`${careerPathwayUiUrl}`)(withRouter(CareersUIWrapper)))
);
