// @flow
import api from 'config/api';
import constantsGenerator from 'utils/constantsGenerator';
import { stringSort } from 'utils/sorters';
import session from 'config/session';
import { injectAsyncReducers } from 'store';

const generateConstants = constantsGenerator('fc/parent');

const [
  GET_PARENT_STUDENTS,
  GET_PARENT_STUDENTS_SUCCESS,
  GET_PARENT_STUDENTS_FAIL,
]: string[] = generateConstants('GET_PARENT_STUDENTS');

export const [IMPERSONATE, IMPERSONATE_SUCCESS, IMPERSONATE_FAIL]: string[] = generateConstants(
  'impersonate'
);

export type State = {
  students: Object[],
  loaded: boolean,
};

const initialState: State = {
  students: [],
  loaded: false,
};

function normalizeStudents(students: Object[]) {
  return students.map((s) => ({ ...s.student })).sort(stringSort('firstName'));
}

/**
 * Reducer
 */
export default function reducer(state: State = initialState, action: Object) {
  switch (action.type) {
    case IMPERSONATE:
    case GET_PARENT_STUDENTS:
      return {
        ...state,
        loaded: false,
      };
    case GET_PARENT_STUDENTS_SUCCESS:
      return {
        ...state,
        loaded: true,
        students: normalizeStudents(action.result),
      };
    case IMPERSONATE_FAIL:
    case GET_PARENT_STUDENTS_FAIL:
      return {
        ...state,
        loaded: true,
      };
    default:
      return state;
  }
}

export function fetchParentStudents(parentId: number) {
  return (dispatch: Function) => {
    // if there is no parentId don't do the call to the server
    if (!parentId) {
      return dispatch({ type: GET_PARENT_STUDENTS_SUCCESS, result: [] });
    }
    return dispatch({
      types: [GET_PARENT_STUDENTS, GET_PARENT_STUDENTS_SUCCESS, GET_PARENT_STUDENTS_FAIL],
      promise: (client: Object) => client.get(`${api.users}/students/${parentId}`),
    });
  };
}

export function impersonateStudent(studentId: number) {
  return {
    types: [IMPERSONATE, IMPERSONATE_SUCCESS, IMPERSONATE_FAIL],
    promise: (client: Object) =>
      client
        .post(`${api.security}/impersonate`, {
          data: {
            value: Number(studentId), // weird issue with api endpoint that detects this as a string
          },
          params: {
            credentials: 'include',
          },
        })
        .then((token) => {
          session.register(token);
          /* TODO reload all user data instead of reloading window */
          window.location = '/main';
        }),
  };
}

injectAsyncReducers({ parent: reducer });
